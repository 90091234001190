import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Alert, CircularProgress, Grid } from '@mui/material';
import StatusIndicator from './StatusIndicator';
import StatusDetailsDialog from './StatusDetailsDialog';

import SETTINGS from 'configuration';
import { CurrentStatusMessage } from './StatusIndicator/types';

export default function ServiceStatus(props: { UTC: boolean }) {

    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [loadedData, setLoadedData] = useState<any>([]);
    const [hasError, setHasError] = useState<string | null>(null);
    const [currentServiceName, setCurrentServiceName] = useState<string | null>(null);

    const getData = () => {
        setIsDataLoading(true);

        fetch(SETTINGS.url.status)
            .then((res) => res.json())
            .then((res) => {
                setLoadedData(res.nodes);
                setHasError(null);
            })
            .catch((error: unknown) => {
                if (error instanceof Error) {
                    setHasError(error.message);
                } else {
                    setHasError("Unknown error");
                }
            })
            .finally(() => {
                setIsDataLoading(false);
            })
    }


    useEffect(() => {
        getData();
        setInterval(getData, 30 * 1000);
    }, [])


    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }} >
            <h2 className='section-header'>Service Status</h2>
            {
                isDataLoading && !loadedData &&
                <CircularProgress sx={{ display: "block", margin: "10px auto" }} />
            }

            {
                hasError &&
                <Box>
                    <Alert severity="error"><b>Error fetching System Status!</b>
                        <br />
                        There might be a temporary network issue that prevents displaying the real-time status of ECMWF services.
                        <br />
                        Try to reload this page. If the problem persists, try again later.
                    </Alert>
                </Box>
            }

            {
                !hasError && loadedData &&
                <Grid container direction="row" columns={{ xs: 2, sm: 4, md: 8 }} >
                    {loadedData.map((item: { node: CurrentStatusMessage }, index: number) => (
                        <StatusIndicator key={index} data={item.node} setCurrentServiceName={setCurrentServiceName} />
                    ))}
                </Grid>
            }
            <StatusDetailsDialog UTC={props.UTC} ServiceName={currentServiceName} onClose={() => {
                setCurrentServiceName(null)
            }
            } />
        </Box>
    );
}
