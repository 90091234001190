import { Switch } from "@mui/material";

export default function TableHeaderWithTimeZoneToggle(props: { UTC: boolean, toggleUTC: (value: boolean) => void }) {
  function toggleTimeZone(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    e.stopPropagation();
    props.toggleUTC(checked);
  }
  return (
    <span>
      Post date (Local
      <Switch
        onClick={(e) => { e.stopPropagation(); }}
        size='small'
        checked={props.UTC}
        onChange={(e, checked) => { toggleTimeZone(e, checked) }}
      />
      UTC)
    </span>
  );
}