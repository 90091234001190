import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';

import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { getFormattedDateTime, parseDateTime } from 'utility';
import SETTINGS from 'configuration';
import { Alert, CircularProgress } from "@mui/material";

import DetailsDialog from "../DetailsDialog";
import TableHeaderWithTimeZoneToggle from 'components/TimeZoneToggle';
import NoRowsOverlay from 'components/NoRowsOverlay';

export type ResponseObj<T> = {
    nodes: Array<{
        node: T
    }>
}

export type Notification = {
    Nid: string;
    Title: string;
    'Start Time': string;
    'Post date': string;
    Type: string;
    Services?: string | { [key: number]: string };
    Action?: string;
}

export type NotificationRow = {
    id: string;
    postDate: number;
    title: string;
    startTime: string;
    services: string,
    type: string;
    action: string;
}


export const notificationsColumnsDefinition: GridColDef[] = [
    {
        field: 'postDate',
        // headerName: 'Post date (UTC)',
        width: 250,
        type: 'number',
        hideable: false,
        cellClassName: "monospaced-text",
    },
    {
        field: 'services',
        headerName: 'Services impacted',
        minWidth: 200,
        flex: 1,
        hideable: false
    },
    {
        field: 'type',
        headerName: 'Type',
        minWidth: 60,
        flex: 1,
        hideable: false
    },
    {
        field: 'title',
        headerName: 'Title',
        width: 510,
        hideable: false,
    },
    {
        field: 'action',
        headerName: 'User Action Required',
        width: 160,
        valueFormatter: (value) => (String(value).toLowerCase() === "no" ? "" : "Yes"),
        flex: 1,
        hideable: false
    },
];

const fetchNotifications = async (): Promise<ResponseObj<Notification>> => {
    const res = await fetch(SETTINGS.url.notifications);
    return await res.json();
}

export default function Notifications(props: { UTC: boolean; toggleUTC: (value: boolean) => void }) {

    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [loadedData, setLoadedData] = useState<Array<NotificationRow> | null>(null);
    const [hasError, setHasError] = useState<string | null>(null);
    const [currentNid, setCurrentNid] = useState<string | null>(null);

    function notificationPopUp(e: GridRowParams<NotificationRow>) {
        setCurrentNid(e.row.id);
    }

    const getData = () => {
        setIsDataLoading(true);

        fetchNotifications()
            .then((res) => {
                setLoadedData(res.nodes.map((item) => {
                    // 'services' can be a string OR an object with strings as property '1', property '2' etc
                    const nodeServices = item.node['Services'];

                    const services = nodeServices ?
                        (typeof nodeServices === 'object' ? Object.values(nodeServices) : [nodeServices]).join(", ")
                        : ''
                    return {
                        id: item.node.Nid,
                        postDate: parseDateTime(item.node['Post date']),
                        title: item.node['Title'],
                        startTime: item.node['Start Time'],
                        services: services,
                        type: item.node['Type'],
                        action: item.node['Action'] ?? ''
                    }
                }))
                setHasError(null);
            })
            .catch((error: unknown) => {
                if (error instanceof Error) {
                    setHasError(error.message);
                } else {
                    setHasError("Unknown error");
                }
            })
            .finally(() => {
                setIsDataLoading(false);
            })
    }

    useEffect(() => {
        getData();
        setInterval(getData, 30 * 1000);
    }, [])

    // header of first row needs to change accoring to timezone 
    const columns = [...notificationsColumnsDefinition]
    columns[0].renderHeader = (column) => (<TableHeaderWithTimeZoneToggle UTC={props.UTC} toggleUTC={props.toggleUTC} />)
    columns[0].valueFormatter = (row) => getFormattedDateTime(row, props.UTC);

    return (
        <Box>
            <h2 className='section-header'>Notifications</h2>

            <Box sx={{ height: 533, width: '100%' }}>

                {
                    isDataLoading && !loadedData &&
                    <CircularProgress sx={{ display: "block", margin: "10px auto" }} />
                }

                {
                    hasError &&
                    <Box>
                        <Alert severity="error"><b>Error fetching Notifications!</b>
                            <br />
                            There might be a temporary network issue that prevents displaying the list of Notifications.
                            <br />
                            Try to reload this page. If the problem persists, try again later.
                        </Alert>
                    </Box>
                }

                {
                    !hasError && loadedData &&
                    <DataGrid
                        onRowClick={notificationPopUp}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'startTime', sort: 'desc' }],
                            },
                            pagination: {
                                paginationModel: { pageSize: 10, page: 0 },
                            },
                        }}
                        slots={{
                            noRowsOverlay: NoRowsOverlay,
                        }}
                        rows={loadedData}
                        columns={columns}
                        rowHeight={42}
                        disableRowSelectionOnClick
                    />
                }
                <DetailsDialog UTC={props.UTC} Nid={currentNid} onClose={() => setCurrentNid(null)} />
            </Box>
        </Box>
    );
}
