import { Grid } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { MouseEvent } from 'react';
import { ledColor } from 'utility';
import { CurrentStatusMessage } from './types';

export default function StatusIndicator(props: { data: CurrentStatusMessage, setCurrentServiceName: Function }) {

  function statusPopup(e: MouseEvent<HTMLDivElement>) {
    props.setCurrentServiceName(props.data.Title);
  }
  return (
    <Grid item xs={1} onClick={statusPopup} >
      <Box sx={{ display: 'flex' }}>
        <Chip
          icon={<CircleIcon color={ledColor(props.data.Status)} />}
          label={props.data.Title}
          variant="outlined"
        />
      </Box>
    </Grid>
  )
}
