import { ToggleButton, ToggleButtonGroup } from "@mui/material";

export function PastOrUpcoming(props: { filterSessions: boolean; handleChange: Function; }) {

  return (
    <>
      <ToggleButtonGroup
        exclusive
        value={props.filterSessions.toString()}
        onChange={(e, value) => {
          props.handleChange(value === "true");
        }}
      >
        <ToggleButton value="true">Upcoming or still running</ToggleButton>
        <ToggleButton value="false">Past</ToggleButton>
      </ToggleButtonGroup>
    </>

  );
}
